<template>
    <div class="haccp-bg pr">
        <div class="t-head jac">
            <h1>人员绩效管理</h1>
            <full-screen  style="position: absolute;right: 20px"></full-screen>
        </div>
        <div class="search-box">
            <el-date-picker
                    v-model="timeValue"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    unlink-panels
                    :picker-options="pickerOptions"
            ></el-date-picker>
            <div class="button" style="margin-left: 5px"  @click="getList()">筛选</div>
            <div class="button" style="margin-left: 5px"  @click="handleManage()">人员配置</div>
        </div>
        <el-row :gutter="20">
            <el-col
                    :span="24"
                    style="margin-bottom: 5px;padding: 10px 20px"

            >
                <div class="box-border">
                    <div id="haccp-chart" >
                        <div class="bg-color-black">
                            <div class="d-flex pt-2 pl-2">
                                <span>
                                </span>
                                <div class="d-flex">
                                    <div class='box-title'>
                                        <span class="fs-xl text mx-2 "><dv-decoration-11 style="width:200px;height:60px;">农事农活</dv-decoration-11></span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showChart" style="width: 100%;height: 95%">
                                <kpi-chart  :list="list"/>
<!--                                <three-d-chart :list="list"></three-d-chart>-->
                            </div>

                        </div>
                    </div>
                </div>


            </el-col>
        </el-row>

        <el-dialog title="操作记录" :visible.sync="dialogTableVisible">
            <el-table :data="baseDetail" border height="800">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-table
                                :data="props.row.details"
                                border
                                height="400"
                                style="width: 100%">
                            <el-table-column
                                    prop="name"
                                    label="名称"
                                    width="40%">
                            </el-table-column>
                            <el-table-column
                                    prop="createdTime"
                                    label="时间"
                                    width="60%">
                                <template slot-scope="scope">
                                    {{scope.row.createdTime.replace('T',' ') }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column
                        align="center"
                        property="baseName"
                        label="基地名称"
                ></el-table-column><el-table-column
                    align="center"
                    property="count"
                    label="操作次数"
            ></el-table-column>
            </el-table>
            <el-dialog
                    top="5vh"
                    width="400px"
                    title="质控详情"
                    :visible.sync="innerVisible"
                    append-to-body
            >
            </el-dialog>
        </el-dialog>
        <el-pagination
                background
                :page-size="10"
                :pager-count="5"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :hide-on-single-page="true"
                :total="total">
        </el-pagination>
        <el-dialog title="权限管理" :visible.sync="manageVisible" width="35%">
            <div>
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedUser" @change="handleCheckedCitiesChange" size="medium">
                    <el-checkbox style="width: 20%;margin: 20px" v-for="user in userList" :label="user.user_id" :key="user.user_id" :border="true"><div> {{user.user_name}}</div></el-checkbox>
                </el-checkbox-group>
            </div>
            <div>
                <el-button type="primary" round @click="handleSaveUser">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from "../../../util/extra-api";
    import bus from '@/util/bus'
    import axios from "../../../util/api";
    export default {
        data() {
            return {
                list: [],
                controlData: null,
                baseDetail: null,
                rowId: null,
                clickRow: null,
                tableClickRow: null,
                timeValue: [],
                selectedPis: [],
                selectedPiInfo: [],
                selectedPiInfoCopy: [],
                start_time: "",
                end_time: "",
                traceUrl: "",
                dialogVisible: false,
                dialogTableVisible: false,
                innerVisible: false,
                tableFlag: true,
                compareFlag: false,
                form: {
                    control_info: "",
                    pi_id: null,
                },
                moveTemp: {
                    id: null,
                    weight: null,
                },
                dialogStatus: "create",
                processOptions: [],
                pickerOptions: {
                    shortcuts: [
                        {
                            text: "最近一周",
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                                picker.$emit("pick", [start, end]);
                            },
                        },
                        {
                            text: "最近一个月",
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                                picker.$emit("pick", [start, end]);
                            },
                        },
                        {
                            text: "最近三个月",
                            onClick(picker) {
                                const end = new Date();
                                const start = new Date();
                                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                                picker.$emit("pick", [start, end]);
                            },
                        },
                    ],
                },
                listData: [{},{},{}],
                compareData: [],
                query:{
                    companyId:'',
                    startTime: '',
                    endTime: ''
                },
                haccpTemplateId: null,
                total: 0,
                showChart: false,
                manageVisible:false,
                checkAll: false,
                checkedUser: [],
                isIndeterminate: true,
                userList:[],
            };
        },
        computed: {
        },
        created() {},
        mounted() {
            this.query.companyId = localStorage.getItem("company_id")
            this.getList()
            bus.$off()
            bus.$on('clickHaccpBar',param =>{
                // this.samplingBatchData = this.list[param.index].haccpChartData[param.dataIndex].recordChartVos
                // this.haccpTemplateId = this.list[param.index].haccpChartData[param.dataIndex].id
                let params = {
                    companyId: this.query.companyId,
                    employeeId: this.list[param.dataIndex].userId,
                    startTime: this.query.startTime,
                    endTime: this.query.endTime
                }
                api.get('/v1/pc/plantingScreen/staffOperationDetail',params).then(res =>{
                    if(res.code == 200){
                        this.baseDetail = res.data
                        this.dialogTableVisible = true
                    }
                })

            })
        },
        methods: {
            handleManage(){
                axios.get("/pc/company-user-iot/getHasIotUser",{ company_id: this.query.companyId ,other_table_name : 'kpi',other_table_id: 0}).then((res) => {
                    if(res.code == 200){
                        this.userList = res.data
                        this.checkedUser = res.data.filter(m => m.tag).map(m => m.user_id)
                        this.manageVisible = true
                    }
                });
            },
            handleSaveUser(){
                let data = {
                    user_id: this.checkedUser,
                    company_id: this.query.companyId,
                    other_table_name: 'kpi',
                    other_table_id: 0
                }
                axios.post("/pc/company-user-iot/save", data).then(res=>{
                    if(res.code == 200){
                        this.manageVisible = false
                        this.getList()
                    }
                })
            },
            handleCheckedCitiesChange(value) {
                this.checkedUser = value
                //this.checkedUser = value.map(m => m.user_id)
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.userList.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.userList.length;
            },
            handleCheckAllChange(val) {
                this.checkedUser = val ? this.userList.map(m => m.user_id) : [];
                this.isIndeterminate = false;
            },

            handleCurrentChange(val){
                this.query.page = val
                this.getList()
            },
            getList(){
                this.showChart = false
                if (this.timeValue && this.timeValue.length > 0) {
                    this.query.startTime = this.timeValue[0];
                    this.query.endTime = this.timeValue[1];
                }else{
                    this.query.startTime = '';
                    this.query.endTime = '';
                }
                api.get('/v1/pc/plantingScreen/staffOperationChart',this.query).then(res =>{
                    if(res.code === 200){
                        this.list = res.data.sort((a,b) => b.count -  a.count )
                        this.total = res.total
                        this.showChart = true
                    }
                })
            },

        },
    };
</script>
<style lang="scss" scoped>

    .record-bg{
        background-image: url("../../../assets/image/cool-background.png");
        overflow: hidden;
        border-radius: 20px;
        background-size: cover;
        z-index: 100;
        /*background-repeat: no-repeat;*/
    }
    .haccp-bg {
        transform-origin: 0px 0px 0px;
        background-image: url("../../../assets/image/mapbg.png");
        background-size: cover;
        background-position: 50% 0;
        background-color: rgb(0, 0, 0);
        min-width: auto;
        width: 100%;
        min-height: auto;
        height: 100%;
        overflow: hidden;
        overflow-x: hidden;
    }
    .search-box{
        padding: 10px;
        text-align: left;
    }
    $box-height: 600px;
    $box-width: 100%;
    #haccp-chart {
        padding: 20px 16px;
        height: $box-height;
        width: $box-width;
        border-radius: 5px;
        .bg-color-black {
            height: $box-height - 35px;
            border-radius: 10px;
            width: 100%;
        }
        .text {
            color: #c3cbde;
        }
        .chart-box {
            margin-top: 16px;
            width: 170px;
            height: 170px;
            .active-ring-name {
                padding-top: 10px;
            }
        }
    }
    .df{
        display: flex;
    }
    @for $i from 1 through 24 {
        .flex-#{$i} {
            flex: 1 round(percentage($i/24));
        }
    }
    .p50{
        padding:50px 20px!important;
        &>div{
            padding: 10px 0;
            border-top: 1px solid #7bbfea;
            display: flex;
            align-items: center;
        }
        &:last-child{
            padding:  0;
            border-bottom: 1px solid #7bbfea;
        }
    }
    .box-border{
        border: 1px solid #afdfe4;
        padding: 10px;
        border-radius: 15px;
        box-shadow: #afb4db 10px 10px 15px ;
    }
    $color: #2194E0;
    @keyframes sheen {
        0% {
            transform: skewY(-45deg) translateX(0);
        }
        100% {
            transform: skewY(-45deg) translateX(12.5em);
        }
    }
    .button {
        width: 80px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        color: #33a3dc;
        border: 2px solid $color;
        font-size: 16px;
        display: inline-block;
        border-radius: 15px;
        transition: all 0.2s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-right: 10px;
        &:before {
            content: "";
            background-color: rgba(255,255,255,0.5);
            height: 100%;
            width: 3em;
            display: block;
            position: absolute;
            top: 0;
            left: -4.5em;
            transform: skewX(-45deg) translateX(0);
            transition: none;
        }
        &:hover {
            cursor:pointer;
            background-color: $color;
            color: #fff;
            border-bottom: 4px solid darken($color, 10%);
            &:before {
                transform: skewX(-45deg) translateX(13.5em);
                transition: all 0.5s ease-in-out;
            }
        }
    }
    $stark-light-blue: #00fffc;
    $stark-med-blue: #00a4a2;
    #fade-box {
        animation: form-entry 2s ease-in-out;
        /*background: #111;*/
        /*background: linear-gradient(#004746, #111);*/
        border: 1px solid $stark-med-blue;
        box-shadow: 0 0 15px #00fffd;
        border-radius: 20px;
        /*display: inline-block;*/
        /*height: 220px;*/
        /*margin: 200px auto 0;*/
        /*position: relative;*/
        z-index: 4;
        /*width: 500px;*/
        transition: 1s all ;

        &:hover {
            border: 1px solid #00fffd;
            box-shadow: 0 0 25px #00fffd;
            transition: 1s all ;
        }
    }
    @keyframes form-entry {
        0% {
            max-height: 80vh;
            width: 0;
            opacity: 0;
            padding: 0;
        }
        20% {
            max-height: 80vh;
            border: 4px solid $stark-med-blue;
            width: 0;
            opacity: 0;
            padding: 0;
        }


        40% {
            width: 0;
            max-height: 80vh;
            border: 4px solid $stark-med-blue;
            opacity: 1 ;
            padding: 0;
        }

        100% {
            max-height: 80vh;
            width: 800px;

        }
    }
    @keyframes input-entry {
        0% {
            opacity: 0;
        }
        90% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }


</style>
<style>
    .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0px!important;
    }
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
        border-radius: 2px;
        height: 50px;
        background: #eee;
    }
    .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.4);
    }
</style>
